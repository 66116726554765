<template>
  <div>
    <v-switch
      v-bind="$attrs"
      :input-value="getValue"
      @change="updateModelValue"
      :style="$attrs.style"
      :class="$attrs.class"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { get } from 'lodash';
export default {
  name: 'SwitchWrapper',
  data() {
    return {
      switchValue: false,
    };
  },
  computed: {
    subPath() {
      return this.$attrs.subPath;
    },
    fieldIdentifier() {
      return `${this.subPath}.${this.$attrs.dataPath}`;
    },
    getValue() {
      return get(this.$attrs.data, this.fieldIdentifier);
    },
  },
  methods: {
    ...mapActions(['updateDeepPathValue']),
    updateModelValue() {
      this.updateDeepPathValue({
        path: this.fieldIdentifier,
        subPath: this.subPath,
        value: !this.getValue,
      });
    },
  },
  mounted() {
    this.switchValue = this.getValue;
  },
  inheritAttrs: false,
};
</script>
