<template>
  <component
    :is="getComponentName(component.name)"
    v-bind="{
      ...component.props,
      subPath,
      dataPath: this.component.model,
      signedUrls,
      data,
      assetSubPath,
    }"
    v-model="modelValue"
    @input="updateModelValue"
    :style="component.style"
    :class="component.class"
    v-if="renderOn(component.props)"
  >
    <template v-if="component.children && component.children.length">
      <GenericComponent
        v-for="(child, index) in component.children"
        :key="`generic-layout-component-child-${index}`"
        :component="child"
        :data="data"
        :subPath="subPath"
        :signedUrls="signedUrls"
        :assetSubPath="assetSubPath"
      />
    </template>
    <template v-else>
      <span v-if="component.content">{{ component.content }}</span>
    </template>
  </component>
</template>
<script>
import { VCol, VRow, VTextField } from 'vuetify/lib';
import RichTextEditor from '@/components/RTE/RichTextEditor.vue';
import FileInputWrapper from '@/components/lessonPlanTemplate/commonLpComponents/layouts/generic/component-wrappers/FileInputWrapper.vue';
import TeacherTipWrapper from '@/components/lessonPlanTemplate/commonLpComponents/layouts/generic/component-wrappers/TeacherTipWrapper.vue';
import SwitchWrapper from '@/components/lessonPlanTemplate/commonLpComponents/layouts/generic/component-wrappers/SwitchWrapper.vue';
import { get, set } from 'lodash';
export default {
  name: 'GenericComponent',
  components: {
    VCol,
    VRow,
    VTextField,
    RichTextEditor,
    FileInputWrapper,
    TeacherTipWrapper,
    SwitchWrapper,
  },
  props: {
    component: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number, Boolean, Object, Array],
      default: null,
    },
    data: {
      type: Object,
    },
    subPath: {
      type: String,
    },
    signedUrls: {
      type: Object,
    },
    assetSubPath: {
      type: String,
    },
  },
  computed: {
    modelValue: {
      get() {
        if (this.component.model) {
          return get(this.data, `${this.subPath}.${this.component.model}`);
        }
        return null;
      },
      set(newValue) {
        if (this.component.model) {
          this.$emit('input', newValue);
          set(this.data, `${this.subPath}.${this.component.model}`, newValue);
        }
      },
    },
  },
  methods: {
    renderOn(props) {
      if (props?.renderOn) {
        return get(this.data, `${this.subPath}.${props.renderOn.key}`) == props.renderOn.value;
      }
      return true;
    },
    getComponentName(name) {
      const componentMap = {
        row: 'v-row',
        column: 'v-col',
        input: 'v-text-field',
        rte: 'RichTextEditor',
        fileUpload: 'FileInputWrapper',
        teacherTip: 'TeacherTipWrapper',
        switch: 'SwitchWrapper',
      };
      return componentMap[name] || name;
    },
    updateModelValue(newValue) {
      if (this.component.model) {
        this.$emit('input', newValue);
        set(this.data, `${this.subPath}.${this.component.model}`, newValue);
      }
    },
  },
};
</script>
