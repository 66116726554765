<template>
  <div>
    <div class="mgn-Ts mgn-Bs">
      <XSDisplayCard :title="AppTexts.TEACHER_TIP">
        <RichTextEditor
          :canShowDiffIcon="$attrs.canShowDiffIcon"
          :isDiffChanged="isTeacherTipGuideChanged()"
          :fieldIdentifier="fieldIdentifier"
          :placeholder="AppTexts.TEACHER_TIP_DESCRIPTION"
          v-model="teacherTip"
          :includeOnlyToolBarActions="BASIC_TOOLS"
          class="mgn-Bm"
        />
      </XSDisplayCard>
    </div>
  </div>
</template>
<script>
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor.vue';
import AppTexts from '@/constants/AppTexts';
import XSDisplayCard from '@/components/lessonPlan/XSDisplayCard';
import { get, set } from 'lodash';
export default {
  data() {
    return {
      BASIC_TOOLS,
      AppTexts,
    };
  },
  components: {
    RichTextEditor,
    XSDisplayCard,
  },
  computed: {
    subPath() {
      return this.$attrs.subPath;
    },
    dataPath() {
      return this.$attrs.dataPath;
    },
    fileIdentifier() {
      return `${this.subPath}.${this.dataPath}`;
    },
    teacherTip: {
      get() {
        return get(this.$attrs.data, this.fileIdentifier);
      },
      set(value) {
        set(this.$attrs.data, this.fileIdentifier, value);
      },
    },
  },
  methods: {
    isTeacherTipGuideChanged() {
      return true;
    },
  },
  inheritAttrs: false,
};
</script>
