import Vue from 'vue';

export function parsePath(path) {
  path = path.replace(/\[(\d+)\]/g, '.$1');
  return path.split('.');
}

export function setNestedObject(obj, path, value) {
  const pathArray = parsePath(path);
  pathArray.reduce((acc, key, idx) => {
    if (idx === pathArray.length - 1) {
      Vue.set(acc, key, value);
    } else {
      if (acc[key] == null) {
        const nextKey = pathArray[idx + 1];
        if (!isNaN(nextKey)) {
          Vue.set(acc, key, []);
        } else {
          Vue.set(acc, key, {});
        }
      }
    }

    return acc[key];
  }, obj);
}
