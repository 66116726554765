<template>
  <div>
    <GenericComponent
      v-for="(component, index) in components"
      :key="`generic-layout-component-${index}`"
      :component="component"
      :data="data"
      :subPath="subPath"
      :isAssetUploadRequired="isAssetUploadRequired"
      :canShowDiffIcon="canShowDiffIcon"
      :showImageBrief="showImageBrief"
      :isNotReadOnly="isNotReadOnly"
      :signedUrls="signedUrls"
      :assetSubPath="assetSubPath"
    />
  </div>
</template>

<script>
import GenericComponent from './GenericComponent.vue';
export default {
  name: 'DynamicLayout',
  components: {
    GenericComponent,
  },
  props: {
    layoutName: {
      type: String,
      required: true,
    },
    layoutTemplate: {
      type: String,
      required: true,
    },
    components: {
      type: Array,
      required: true,
    },
    data: {
      type: Object,
    },
    subPath: {
      type: String,
    },
    isAssetUploadRequired: {
      type: Boolean,
      default: false,
    },
    canShowDiffIcon: {
      type: Boolean,
      default: false,
    },
    showImageBrief: {
      type: Boolean,
      default: false,
    },
    isNotReadOnly: {
      type: Boolean,
      default: false,
    },
    signedUrls: {
      type: Object,
      default: () => {},
    },
    assetSubPath: {
      type: String,
      default: '',
    },
  },
};
</script>
